import React from 'react';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';

const NotFoundPage = () => (
    <Layout>
        <Seo title='404: Not Found' />
        <h1>That page doesn&#39;t exist.</h1>
        <br />
    </Layout>
);

export default NotFoundPage;
